import XLSX from 'xlsx';

const exportExcelFile = (fileName, header, data) => {
  const worksheet = XLSX.utils.json_to_sheet(data, { header });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet);
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

const exportMultiExcelFile = (fileName, sheets) => {
  const workbook = XLSX.utils.book_new();
  sheets.forEach((sheet) => {
    const worksheet = XLSX.utils.json_to_sheet(sheet.data, { header: sheet.header });
    XLSX.utils.book_append_sheet(workbook, worksheet, sheet.title);
  });
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

const removeSpecialText = (str) => {
  const reg = /[\\/?*[\]]/gi;
  if (reg.test(str)) {
    return str.replace(reg, '').substr(0, 28);
  }
  return str.substr(0, 28);
};

export default {
  exportExcelFile,
  exportMultiExcelFile,
  removeSpecialText,
};
