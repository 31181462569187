import { format } from '@/common/date.util';
import { FRONTEND_URL } from '@/common/config';

export const getRawSubmissionHeader = questions => ([
  'Timestamp',
  'Company',
  ...questions.map(({ no }) => no),
]);
const getHeader = questions => questions.reduce((acc, elem) => {
  acc[elem.no] = elem.title;
  return acc;
}, {});
const dataMapper = (acc, elem, row) => {
  acc.Timestamp = format(row.createdDate, 'YYYY.MM.DD HH:mm:ss');
  acc.Company = row.company ? String(row.company) : '';
  if (['MATRIX_CHILD', 'RATING'].includes(elem.questionType) && !Number.isNaN(Number(elem.answer)) && Number(elem.answer) !== 0) {
    acc[elem.no] = Number(elem.answer);
  } if (['NPS'].includes(elem.questionType)
    && !Number.isNaN(Number(elem.answer))) {
    acc[elem.no] = Number(elem.answer);
  } else {
    acc[elem.no] = elem.answer ? String(elem.answer) : '';
  }
  return acc;
};
const getData = rows => rows.map(row => row.eachAnswers
  .reduce((acc, elem) => dataMapper(acc, elem, row), {}));
export const getRawSubmissionData = (questions, rows) => {
  const header = getHeader(questions);
  const data = getData(rows);
  return [
    header,
    ...data,
  ];
};

export const getRawReportSubmissionHeader = questions => [
  'id',
  ...getRawSubmissionHeader(questions),
];

export const getRawReportSubmissionData = (questions, rows) => {
  const header = getHeader(questions);
  const data = rows.map(row => row.eachAnswers
    .reduce((acc, elem) => {
      acc.id = row.id;
      return dataMapper(acc, elem, row);
    }, {}));
  return [
    header,
    ...data,
  ];
};

export const getResponseStatusData = (list) => {
  const receivers = list.map(person => ({
    개별링크: `${FRONTEND_URL}/survey/${person.mixedId}`,
    응답여부: person.response ? 'O' : 'X',
    Timestamp: format(person.date, 'YYYY.MM.DD HH:mm:ss'),
  }));
  return [
    // { url: 'url', 응답여부: '응답여부', timestamp: 'timestamp' },
    ...receivers,
  ];
};
