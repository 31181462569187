<template>
  <div class="trial-main">
    <trial-header/>
    <router-view :key="$route.fullPath"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TrialHeader from '@/components/Header/TrialHeader.vue';
import bus from '@/common/bus.util';

export default {
  name: 'TrialMain',
  components: {
    TrialHeader,
  },
  async created() {
    bus.$emit('start:spinner');
    await this.fetchTrialSurvey();
    this.$router.push('/editor', () => {});
    bus.$emit('end:spinner');
  },
  methods: {
    ...mapActions(['fetchTrialSurvey']),
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';
.trial-main {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: $header_height_size;
  background: $white_smoke_95;
}
</style>
