<template>
  <div class='trial-header'>
    <div class="wrapper">
      <div class='menu-list'>
        <router-link to="/editor">
          {{ $t('trial_header.editor') }}
        </router-link>
        <router-link to="/feedback">
          {{ $t('trial_header.feedback') }}
        </router-link>
      </div>
      <div v-if="isSurveySRM"
           class="trial-header__language"
           :class="{'trial-header__language--feedback': $route.name === 'feedback'}">
        <select-language/>
      </div>
      <div v-if="$route.name === 'feedback'" class="button-group right">
        <!-- Excel Export -->
        <button
          class="export-button secondary" data-testid="export-button"
          @click="exportExcel">
          <span class="img">{{ $t('status_page.export_image') }}</span>
          <span>Export</span>
        </button>
      </div>
      <div v-else class="right" @click="moveTologout">
        <div class="logout-image"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SURVEY_ID_SRM, TRIAL_FRONTEND_URL } from '@/common/config';
import file from '@/common/file.util';
import { getRawSubmissionHeader, getRawSubmissionData } from '@/common/data-format.util';
import SelectLanguage from '@/components/Common/SelectLanguage.vue';

export default {
  name: 'TrialHeader',
  components: { SelectLanguage },
  computed: {
    ...mapGetters(['trialSurvey']),
    isSurveySRM() {
      return this.trialSurvey.id === SURVEY_ID_SRM;
    },
    trialSurveyUrl() {
      return `${TRIAL_FRONTEND_URL}/survey/trialSurveyId`;
    },
  },
  methods: {
    ...mapActions(['getTrialSubmissionsForExcel', 'trialLogout']),
    async moveTologout() {
      await this.trialLogout();
      this.$router.push('/login');
    },
    async exportExcel() {
      const submissions = await this.getTrialSubmissionsForExcel();
      const header = getRawSubmissionHeader(submissions.questions);
      const data = getRawSubmissionData(submissions.questions, submissions.rows);
      file.exportExcelFile(this.trialSurvey.name, header, data);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';

.trial-header {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: $header_height_size;
  height: $header_height_size;
  width: 100%;
  background: $white;
  box-shadow: $header-shadow;
  z-index: 9;

  .wrapper {
    position: relative;
    max-width: 1920px;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    .menu-list {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;

      a {
        width: 80px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: $font_size_16;
        color: $charcoal_grey;
        cursor: pointer;
        position: relative;
        transition: all .2s ease-in-out;
        border-bottom: 2px solid transparent;

        &:hover {
          background-color: rgba(0, 202, 148, 0.1);
        }

        &.router-link-exact-active {
          color: $primary_color;
          font-weight: bold;
          border-bottom: 2px solid $primary_color;
        }
      }

      a {
        color: black;
        text-decoration: none;
      }
    }

    .button-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute;

      button {
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }
      }

      .export-button {
        .img {
          width: 13px;
          height: 13px;
          background-size: 13px 13px;
          background-image: url('~@/assets/images/ico-export-excel-grey@3x.png');
          margin-right: 5px;
          text-indent: -999em;
        }

        &:hover {
          .img {
            background-image: url('~@/assets/images/ico-export-excel-active@3x.png');
          }
        }
      }
    }

    .right {
      position: absolute;
      right: 0;
      margin-right: 23px;

      .logout-image {
        width: 20px;
        height: 20px;
        background-size: 20px 20px;
        background-repeat: no-repeat;
        background-image: url("~@/assets/images/logout.svg");
        cursor: pointer;

        &:hover {
          background-image: url('~@/assets/images/logout_green.svg');
        }
      }
    }
  }

  &__language {
    position: absolute;
    right: 65px;

    &.trial-header__language--feedback {
      right: 120px;
    }
  }
}

@media only screen and (max-width: $max_width_size_for_mobile) {
  .sub-header {
    .wrapper {
      .menu-list {
        a {
          width: 62px;

          > span {
            font-size: .88rem;
          }
        }
      }
    }
  }
}
</style>
